/* eslint-disable import/prefer-default-export */
import { useI18n } from '#imports';

/**
 * Format datetime.
 *
 * @param {mixed} datetime
 * @param {Object} options
 */
export const formatDateTime = (datetime, options) => {
  const { locale } = useI18n({ useScope: 'global' });
  const intl = new Intl.DateTimeFormat(locale.value, {
    ...options,
  });
  return intl.format(typeof datetime === 'number' || typeof datetime === 'object' ? datetime : new Date(datetime.replace(/ /, 'T')));
};

/**
 * Format currency.
 *
 * @param   {Number}  number
 *
 * @return  {String}
 */
export const formatCurrency = (number) => {
  const { locale } = useI18n({ useScope: 'global' });
  const hasDecimals = number % 1 !== 0;
  const formatter = new Intl.NumberFormat(locale.value, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(number) + (hasDecimals ? '' : ',-');
};
